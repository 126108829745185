import React from "react"
import Layout from '../components/layout'; 
import DropdownMenu from '../components/dropdownMenu'
import image from '../images/vector-image-3.jpg';
import dropdownStyles from '../styles/dropdown.module.scss'

const ProductPage = () => {
    return(
        <Layout>
            <DropdownMenu/>
            <img className={dropdownStyles.image} src={image} alt="imagen productos" /> 
        </Layout>
    ) 
}
export default ProductPage;
